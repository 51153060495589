export default {
  head() {
    // Access the page data from the component using the `getPageData` method
    const pageData = this.getPageData();
    const comments = this.getPageComments() || [];

    if (!pageData) {
      return {};
    }

    const seoData = pageData.attributes?.SEO || {};
    const authorName = seoData.authorName || this.$config.defaultAuthor;
    const siteName = seoData.metaTitle || this.$config.defaultSiteName;
    const description = seoData.metaDescription || this.$config.defaultDescription;
    const imageUrl = seoData.metaImage?.data?.attributes?.url || this.$config.defaultImage;

    // Type Mapping
    const typeMapping = {
      'website': 'WebPage',
      'article': 'Article',
    };
    const schemaType = typeMapping[seoData.type?.toLowerCase()] || 'WebPage';

    // Dates
    const createdAt = pageData.attributes?.createdAt;
    const updatedAt = pageData.attributes?.updatedAt;
    const datePublished = createdAt ? new Date(createdAt).toISOString() : undefined;
    const dateModified = updatedAt ? new Date(updatedAt).toISOString() : undefined;

    // Language
    const inLanguage = this.$i18n?.locale || 'en-US';

    // SameAs Links
    const sameAsLinks = seoData.sameAs || [
      'https://facebook.com/onvocado',
      'https://www.instagram.com/onvocado.app/',
      'https://linkedin.com/company/onvocadohq/',
    ];

    // Organization Info
    const organization = {
      '@type': 'Organization',
      '@id': `${this.$config.baseUrl}/#organization`,
      'name': this.$config.defaultPublisher || 'Onvocado',
      'url': this.$config.baseUrl,
      'logo': {
        '@type': 'ImageObject',
        '@id': `${this.$config.baseUrl}/#logo`,
        'url': `${this.$config.baseUrl}${this.$icon(192)}`,
        'width': 192,
        'height': 192,
      },
      'sameAs': sameAsLinks.length > 0 ? sameAsLinks : undefined,
    };

    // Determine if current route is the homepage or the blog page
    const isHomepageOrBlogPage = () => {
      const localizedHomepage = `/${this.$i18n.locale}/`;
      const localizedBlogPage = this.localePath('/blog');
      const currentPath = this.$route.path;

      return (
        currentPath === '/' ||
        currentPath === localizedHomepage ||
        currentPath === `/${this.$i18n.locale}` ||
        currentPath === '/blog' ||
        currentPath === `${localizedBlogPage}` ||
        currentPath === `${localizedBlogPage}/`
      );
    };

    const includeSearchAction = isHomepageOrBlogPage();

    // WebSite Info
    const website = {
      '@type': 'WebSite',
      '@id': `${this.$config.baseUrl}/#website`,
      'url': this.$config.baseUrl,
      'name': this.$config.defaultName || 'Onvocado',
      'description': this.$config.defaultDescription || '',
      'publisher': {
        '@id': `${this.$config.baseUrl}/#organization`,
      },
      'inLanguage': inLanguage,
    };

    // If it's the homepage or blog page, add potentialAction for Sitelinks Searchbox
    if (includeSearchAction) {
      website.potentialAction = {
        '@type': 'SearchAction',
        'target': `${this.$config.baseUrl}/search?q={search_term_string}`,
        'query-input': 'required name=search_term_string',
      };
    }

    // ImageObject for Primary Image
    const primaryImage = {
      '@type': 'ImageObject',
      '@id': `${this.$config.baseUrl}${this.$route.fullPath}#primaryimage`,
      'url': `${this.$config.baseUrl}${this.$route.fullPath}`,
      'contentUrl': `${this.$config.baseUrl}${this.$route.fullPath}`,
      'width': 1200,
      'height': 630,
      'caption': siteName,
    };

    // BreadcrumbList
    const breadcrumbItems = [
      {
        '@type': 'ListItem',
        'position': 1,
        'name': 'Home',
        'item': `${this.$config.baseUrl}/`,
      },
    ];

    const pathSegments = this.$route.path.split('/').filter(segment => segment);
    pathSegments.forEach((segment, index) => {
      breadcrumbItems.push({
        '@type': 'ListItem',
        'position': index + 2,
        'name': decodeURIComponent(segment.replace(/-/g, ' ')),
        'item': `${this.$config.baseUrl}/${pathSegments.slice(0, index + 1).join('/')}/`,
      });
    });

    const breadcrumbList = {
      '@type': 'BreadcrumbList',
      '@id': `${this.$config.baseUrl}${this.$route.fullPath}#breadcrumb`,
      'itemListElement': breadcrumbItems,
    };

    // WebPage Info
    const webpage = {
      '@type': 'WebPage',
      '@id': `${this.$config.baseUrl}${this.$route.fullPath}#webpage`,
      'url': `${this.$config.baseUrl}${this.$route.fullPath}`,
      'name': siteName,
      'isPartOf': {
        '@id': `${this.$config.baseUrl}/#website`,
      },
      'about': {
        '@id': `${this.$config.baseUrl}/#organization`,
      },
      'primaryImageOfPage': {
        '@id': `${this.$config.baseUrl}${this.$route.fullPath}#primaryimage`,
      },
      'datePublished': datePublished,
      'dateModified': dateModified,
      'description': description,
      'breadcrumb': {
        '@id': `${this.$config.baseUrl}${this.$route.fullPath}#breadcrumb`,
      },
      'inLanguage': inLanguage,
      'potentialAction': [
        {
          '@type': 'ReadAction',
          'target': [`${this.$config.baseUrl}${this.$route.fullPath}`],
        },
      ],
    };

    // Conditionally add mainEntityOfPage or mainEntity
    let graph = [];
    if (schemaType === 'Article') {
      // Create the Article object
      const article = {
        '@type': 'Article',
        '@id': `${this.$config.baseUrl}${this.$route.fullPath}#article`,
        'headline': siteName,
        'author': {
          '@type': 'Person',
          'name': authorName,
        },
        'datePublished': datePublished,
        'dateModified': dateModified,
        'mainEntityOfPage': {
          '@id': `${this.$config.baseUrl}${this.$route.fullPath}#webpage`,
        },
        'image': {
          '@id': `${this.$config.baseUrl}${this.$route.fullPath}#primaryimage`,
        },
        'publisher': {
          '@id': `${this.$config.baseUrl}/#organization`,
        },
        'inLanguage': inLanguage,
        'description': description,
        'commentCount': comments.length,
      };

      // Add comments as part of the Article schema
      if (comments.length > 0) {
        article.comment = comments.map(comment => ({
          '@type': 'Comment',
          'text': comment.content,
          'dateCreated': new Date(comment.createdAt).toISOString(),
          'author': {
            '@type': 'Person',
            'name': comment.author?.name || 'Anonymous',
          },
        }));
      }

      // Add mainEntity to the WebPage pointing to the Article
      webpage.mainEntity = {
        '@id': `${this.$config.baseUrl}${this.$route.fullPath}#article`,
      };

      // Assemble the @graph
      graph = [
        article,
        webpage,
        primaryImage,
        breadcrumbList,
        website,
        organization,
      ];
    } else {
      // For non-Article pages, proceed without the Article object
      graph = [
        webpage,
        primaryImage,
        breadcrumbList,
        website,
        organization,
      ];
    }

    // Wrap in @context and @graph
    const structuredData = {
      '@context': 'https://schema.org',
      '@graph': graph,
    };

    return {
      script: [
        {
          type: 'application/ld+json',
          json: structuredData,
        },
      ],
    };
  },
  methods: {
    getPageData() {
      return null;
    },
    getPageComments() {
      return null;
    },
  },
};


export default {
  props: {
    seo: {
      type: Object,
      default: () => {},
    },
    createdAt: String,
    updatedAt: String,
    tags: {
      type: Array,
      default: () => [],
    },
  },
  head() {
    const getAbsoluteUrl = () => {
      if (process.server) {
        return process.env.BASE_URL + this.$route.path + '/';
      } else {
        return window.location.origin + this.$route.path;
      }
    };

    // Fallback values using publicRuntimeConfig
    const metaTitle = this.seo?.metaTitle || this.$config.defaultSiteName;
    const metaDescription = this.seo?.metaDescription || this.$config.defaultDescription;
    const metaImageUrl = this.seo?.metaImage?.data?.attributes?.url || this.$config.defaultImage;
    const seoType = this.seo?.type || 'website';

    // Extract titles from tags for article:tag
    const tagTitles = this.tags?.map(tag => tag.attributes?.title) || [];

    // Prepare the meta tags
    const metaTags = [
      {
        hid: 'description',
        name: 'description',
        content: metaDescription,
      },
      // Open Graph Meta Tags
      {
        hid: 'og:title',
        property: 'og:title',
        content: metaTitle,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: metaDescription,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: metaImageUrl,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: getAbsoluteUrl(),
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: seoType,
      },
      {
        hid: 'article:publisher',
        property: 'article:publisher',
        content: this.$config.defaultPublisher,
      },
      // Twitter Meta Tags
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: metaTitle,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: metaDescription,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: metaImageUrl,
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
    ];

    // Add article-specific meta tags if applicable
    if (seoType.toLowerCase() === 'article') {
      if (this.createdAt) {
        metaTags.push({
          hid: 'article:published_time',
          property: 'article:published_time',
          content: this.formatDate(this.createdAt),
        });
      }

      if (this.updatedAt) {
        metaTags.push({
          hid: 'article:modified_time',
          property: 'article:modified_time',
          content: this.formatDate(this.updatedAt),
        });
      }

      // Add tags if available
      tagTitles.forEach((title, index) => {
        metaTags.push({
          hid: `article:tag-${index}`,
          property: 'article:tag',
          content: title,
        });
      });
    }

    return {
      title: metaTitle,
      meta: metaTags,
      /* i18n takes care of canonicals now */
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: getAbsoluteUrl(),
      //   },
      // ],
    };
  },
  mounted() {
    if (this.seo?.onvocadoPixelURL) {
      this.insertOnvocadoPixel();
    }
  },
  methods: {
    insertOnvocadoPixel() {
      const onvocadoScriptTag = document.createElement("script");
      onvocadoScriptTag.type = "text/javascript";
      onvocadoScriptTag.id = "onvocadoScript";
      onvocadoScriptTag.charset = "utf-8";
      onvocadoScriptTag.src = (this.seo.onvocadoPixelURL);
      onvocadoScriptTag.defer = true;
      document.head.appendChild(onvocadoScriptTag);
    },
    /**
     * Formats a date string to ISO 8601 format if it's not already.
     * @param {String} dateStr - The date string to format.
     * @returns {String} - The formatted date string.
     */
    formatDate(dateStr) {
      const date = new Date(dateStr);
      if (isNaN(date)) {
        console.warn(`Invalid date format: ${dateStr}`);
        return dateStr; // Return as-is if invalid
      }
      return date.toISOString();
    },
  },
};
